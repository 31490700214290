import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useToast } from "../Toast/toastHook";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { UPDATE_DIRECTORY } from "../../graphql/directoryQueries";

const EditDirectoryView = ({
  directoryId,
  currentTitle,
  handleClose,
  setShowEditModal,
}) => {
  const toast = useToast(3000);
  const [title, setTitle] = useState(currentTitle || ""); // Pre-fill input with current title

  const [updateDirectoryTitle, { loading: updateLoading }] =
    useMutation(UPDATE_DIRECTORY);

  const handleCancelClick = () => {
    setShowEditModal(false);
  };

  const handleUpdateTitle = async () => {
    if (!title.trim()) {
      toast("error", "Title cannot be empty");
      return;
    }

    try {
      const { data } = await updateDirectoryTitle({
        variables: {
          directoryInput: { _id: directoryId, title },
        },
      });

      if (data.updateDirectory) {
        toast("success", "Directory title updated successfully");
        handleClose();
      } else {
        toast("error", "Failed to update directory title");
        handleClose();
      }
    } catch (error) {
      toast("error", "An error occurred while updating the title");
      handleClose();
    }
  };

  return (
    <form>
      <div className="mt-4">
        <input
          label={"Edit Title"}
          name="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full rounded border p-2"
          placeholder="Enter new title"
        />
      </div>
      <div className="mt-6 flex space-x-2">
        <button
          type="button"
          disabled={updateLoading}
          onClick={handleUpdateTitle}
          className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
        >
          {updateLoading ? (
            <div className="animate-spin">
              <AiOutlineLoading3Quarters size={22} />
            </div>
          ) : (
            "Save"
          )}
        </button>
        <button
          onClick={handleCancelClick}
          disabled={updateLoading}
          className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default EditDirectoryView;
