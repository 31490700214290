const Input = ({
  label,
  value,
  name,
  onChange,
  type,
  placeholder,
  error,
  required,
}) => {
  return (
    <>
      <label className="mb-2 block text-[17px] text-blue font-bold ">
        {label}
      </label>
      <input
        value={value}
        name={name}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        required={required}
        min={type === "number" ? 0 : null}
        className="w-full rounded-md border-[1px] border-[#d9d9d9] bg-transparent py-3 px-3 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter placeholder:text-sm "
      />
      {error && <div className="py-1 text-xs text-red">{error}</div>}
    </>
  );
};

export default Input;
