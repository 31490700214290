import { useMutation } from "@apollo/client";
import {
  ADD_DIRECTORY_LIST,
  EDIT_DIRECTORY_LIST,
} from "../../graphql/directoryQueries";
import { useToast } from "../Toast/toastHook";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import Input from "../Input";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { directoryValidationSchema } from "./directory-validation";

const DirectoryListForm = ({
  directoryListId,
  company,
  directoryList,
  formType,
  handleClose,
  onDirectoryListAdded,
}) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [initialValues, setInitialValues] = useState({
    title: "",
    company: {
      id: "",
      name: "",
    },
    details: {
      address: "",
      area: "",
      name: "",
      phone: "",
      notes: "",
      url: "",
      area: "",
      password: "",
    },
  });

  const toast = useToast(3000);
  const formikRef = useRef();

  // Mutation for adding a directory list
  const [
    addDirectoryList,
    { data: addData, loading: addLoading, error: addError },
  ] = useMutation(ADD_DIRECTORY_LIST, {
    variables: { _id: directoryListId },
    onCompleted(result) {
      toast(
        "success",
        `Successfully saved ${result?.addDirectoryList?.response?.[0]?.__typename}!`
      );
      formikRef.current?.resetForm();
      handleClose();
      onDirectoryListAdded();
    },
    onError(e) {
      toast("error", `There was an issue saving the data, please try again`);
      console.error(e);
    },
  });

  // Mutation for editing a directory list
  const [
    editDirectoryList,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(EDIT_DIRECTORY_LIST, {
    onCompleted(result) {
      toast("success", `Successfully updated DIRECTORY_LIST!`);
      formikRef.current?.resetForm();
      handleClose();
      onDirectoryListAdded();
    },
    onError(e) {
      toast("error", `There was an issue updating the data, please try again`);
      console.error(e);
    },
  });

  const handleFormData = () => {
    if (formType === "edit" && company) {
      console.log("company", company.company);
      setInitialValues({
        title: company.title || "",
        details: {
          address:
            company.details?.[0]?.address || company.company.address || "",
          area:
            company.details?.[0]?.area || company.company.serviceAreas || "",
          name: company.details?.[0]?.name || company.company.companyName || "",
          phone: company.details?.[0]?.phone || company.company.phone || "",
          notes: company.details?.[0]?.notes || company.company.notes || "",
          url: company.details?.[0]?.url || company.company.website || "",
          password:
            company.details?.[0]?.password ||
            company.company.onBoardingPassword ||
            "",
        },
      });
    } else {
      formikRef.current?.resetForm();
    }
  };

  useEffect(() => {
    handleFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryList]);

  const handleSave = (values) => {
    const { title, details } = values;
    if (formType === "add") {
      addDirectoryList({
        variables: {
          directoryListInput: {
            company_id: company?.id,
            data: {
              _id: "0",
              directory: directoryList,
              //   title,
              details,
            },
            title,
            // details,
            directory_id: directoryListId, // Ensure the directory ID is passed
          },
        },
      });
    } else {
      editDirectoryList({
        variables: {
          directoryListInput: {
            _id: company._id,
            title,
            details,
            directory: directoryListId,
          },
        },
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={directoryValidationSchema}
      onSubmit={async (values, resetForm) => {
        console.log(values, "values");
        handleSave(values, resetForm);
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        resetForm,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3 flex flex-col gap-6">
            <div className="w-full">
              <Input
                label={"Company Name"}
                name="details.name"
                error={errors.details?.name}
                value={values["details"].name}
                onChange={handleChange}
                type="text"
                placeholder={"Company Name"}
              />
            </div>
            <div className="w-full ">
              <Input
                label={"Company Address"}
                name="details.address"
                error={errors.details?.address}
                value={values["details"].address}
                onChange={handleChange}
                type="text"
                placeholder={"Company Address"}
              />
            </div>
            <div className="w-full ">
              <Input
                label={"Company Phone"}
                name="details.phone"
                error={errors.details?.phone}
                value={values["details"].phone}
                onChange={handleChange}
                type="text"
                placeholder={"Company Phone"}
              />
            </div>
            <div className="w-full ">
              <Input
                label={"Company Website"}
                name="details.url"
                error={errors.details?.url}
                value={values["details"].url}
                onChange={handleChange}
                type="text"
                placeholder={"Company Website"}
              />
            </div>
            <div className="w-full">
              <Input
                label={"Service Area"}
                name="details.area"
                error={errors.details?.area}
                value={values["details"].area}
                onChange={handleChange}
                type="text"
                placeholder={"Service Area"}
              />
            </div>
            <div className="w-full ">
              <Input
                label={"Notes"}
                name="details.notes"
                error={errors.details?.notes}
                value={values["details"].notes}
                onChange={handleChange}
                type="text"
                placeholder={"Notes"}
              />
            </div>
            <div className="w-full">
              <Input
                label={"On Boarding Password"}
                name="details.password"
                error={errors.title}
                value={values["details"].password}
                onChange={handleChange}
                type="text"
                placeholder={"Company Password"}
              />
            </div>
          </div>

          <div className="mt-6 flex space-x-2">
            <button
              type="button"
              disabled={addLoading || editLoading}
              onClick={() => handleClose()}
              className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting || addLoading}
              className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
            >
              {addLoading || editLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={22} />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default DirectoryListForm;
