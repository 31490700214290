import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import Card from "../Card";
import Input from "../Input";
import AdminAutocompleteCheckbox from "../AdminAutocompleteCheckbox";

const CompanyNotificationForm = ({ formik, dropdownRef }) => {
  const handleIsEveryOne = () => {
    formik.setFieldValue("toEveryOne", !formik.values.toEveryOne);
    const allOfficeIDs = formik.values.companies.map((company) => company._id);
    formik.setFieldValue("selectedItems.office", allOfficeIDs);
  };
  return (
    <div id="company-form" className="py-6">
      <Card>
        <div className="border-b border-stroke py-4 px-6">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-end">
              <div className="flex flex-col gap-6 mb-3 w-full md:flex-row">
                {formik.values.selectedItems.agent.length === 0 && (
                  <button
                    onClick={handleIsEveryOne}
                    type="button"
                    className={`flex w-[200px] justify-center rounded ${
                      formik.values.toEveryOne ? "bg-primary" : "bg-slate-300"
                    } p-3 font-medium text-white
                        hover:opacity-90 h-[50px] `}
                  >
                    To All Offices
                  </button>
                )}
                {!formik.values.toEveryOne && (
                  <>
                    {formik.values.selectedItems.agent.length <= 0 && (
                      <AdminAutocompleteCheckbox
                        options={formik.values.companies}
                        formik={formik}
                        rootKey="office"
                        dropdownRef={dropdownRef}
                      />
                    )}
                    {true && (
                      <AdminAutocompleteCheckbox
                        options={formik.values.users}
                        formik={formik}
                        rootKey="agent"
                        dropdownRef={dropdownRef}
                        dependentKey="defaultOffice"
                        dependencyValue={formik.values.selectedItems.office}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mb-2 flex flex-col  gap-6 md:flex-col">
              <div className="w-full ">
                <Input
                  label="Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  type="text"
                  // touched={formik.touched}
                  error={formik.errors.title}
                  placeholder={"Enter title"}
                />
              </div>

              <div className="w-full ">
                <label className="mb-2 block text-black ">Description</label>
                <textarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  type="text"
                  rows={10}
                  placeholder="Enter description"
                  className="w-full rounded-md resize-none border-[1px] border-[#d9d9d9] bg-transparent py-3 px-3 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter placeholder:text-sm "
                />
                {formik.errors.description && (
                  <div className="py-1 text-xs text-red">
                    {formik.errors.description}
                  </div>
                )}
              </div>
              <div className="mt-2 flex justify-end space-x-2">
                <button
                  type="submit"
                  disabled={formik.values.loading}
                  className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 h-[50px]"
                >
                  {formik.values.loading ? (
                    <Spin
                      style={{
                        color: "white",
                      }}
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  ) : (
                    "Send"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default CompanyNotificationForm;
