import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import {
  ADD_COMPANY_IN_DIRECTORY,
  ADD_DIRECTORY_LIST,
  GET_DIRECTORY_LIST,
} from "../../graphql/directoryQueries";

const CompaniesDropdown = ({
  open,
  setOpen,
  directoryId,
  data,
  loading,
  setData,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, setOpen]);

  const handleCompanyClick = () => {
    setOpen(false);
  };

  const dropdownRef = useRef(null);

  const [addCompany] = useMutation(ADD_DIRECTORY_LIST, {
    refetchQueries: [{ query: GET_DIRECTORY_LIST }],
    onCompleted(result) {
      setOpen(false);
    },
    onError(error) {
      console.error("Error adding company:", error);
    },
  });

  const addCompanyToDirectory = (company_id, directory_id) => {
    addCompany({
      variables: {
        directoryListInput: {
          company_id,
          directory_id,
        },
      },
    });
  };

  return (
    <div className="relative inline-block text-left">
      {open && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 w-56 max-h-[400px] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
        >
          <div className="py-1">
            {data &&
              data?.getAllCompanies?.map((company) => (
                <button
                  key={company._id}
                  onClick={() =>
                    addCompanyToDirectory(company._id, directoryId)
                  }
                  className="block hover:bg-[#a7b83b] px-4 py-2 text-sm text-gray-700 w-full text-left"
                >
                  {company.companyName}
                </button>
              ))}
            {loading && (
              <div className="flex justify-center items-center h-fit">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-[#a7b83b]" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompaniesDropdown;
