import { useMutation } from "@apollo/client";
import { ADD_AGENT } from "../../graphql/directoryQueries";
import { useToast } from "../Toast/toastHook";
import { useEffect, useRef, useState } from "react";
import { Formik, FieldArray } from "formik";
import Input from "../Input";

const AddAgentForm = ({
  company,
  formType,
  handleClose,
  onDirectoryListAdded,
  companyId,
}) => {
  const [initialValues, setInitialValues] = useState({
    agent_info: [
      {
        address: "",
        email: "",
        name: "",
        phone: "",
        position: "",
        url: "",
      },
    ],
  });

  const toast = useToast(3000);
  const formikRef = useRef();

  const [addAgent, loading] = useMutation(ADD_AGENT, {
    refetchQueries: ["GetCompanyById"],
    onCompleted() {
      toast("success", `Successfully saved new agent!`);
      formikRef.current?.resetForm();
      handleClose();
      onDirectoryListAdded();
    },
    onError(e) {
      toast("error", "There was an issue saving the data, please try again");
    },
  });

  const handleFormData = () => {
    if (formType === "edit" && company) {
      setInitialValues({
        agent_info: [
          {
            name: "",
            address: "",
            email: "",
            phone: "",
            position: "",
            url: "",
          },
        ],
      });
    } else {
      formikRef.current?.resetForm();
    }
  };

  useEffect(() => {
    handleFormData();
  }, [company, formType]);

  const handleSave = (values) => {
    const { agent_info } = values;

    if (formType === "add") {
      addAgent({
        variables: {
          companyId: companyId,
          agentInfo: agent_info,
        },
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values) => {
        handleSave(values);
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3 flex flex-col gap-6">
            <FieldArray name="agent_info">
              {({ push }) => (
                <>
                  {" "}
                  {values.agent_info.map((agent, index) => (
                    <div key={index} className="mt[-30px]">
                      <div className="w-full mb-3">
                        <Input
                          label={"Name"}
                          name={`agent_info[${index}].name`}
                          error={errors.agent_info?.[index]?.name}
                          value={values.agent_info[index].name}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Name"}
                        />
                      </div>
                      <div className="w-full mb-3">
                        <Input
                          label={"Designation"}
                          name={`agent_info[${index}].position`}
                          error={errors.agent_info?.[index]?.position}
                          value={values.agent_info[index].position}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Designation"}
                        />
                      </div>
                      <div className="w-full mb-3">
                        <Input
                          label={"Location"}
                          name={`agent_info[${index}].address`}
                          error={errors.agent_info?.[index]?.address}
                          value={values.agent_info[index].address}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Location"}
                        />
                      </div>
                      <div className="w-full mb-3">
                        <Input
                          label={"Email"}
                          name={`agent_info[${index}].email`}
                          error={errors.agent_info?.[index]?.email}
                          value={values.agent_info[index].email}
                          onChange={handleChange}
                          type="email"
                          placeholder={"Email"}
                        />
                      </div>
                      <div className="w-full mb-3">
                        <Input
                          label={"Phone"}
                          name={`agent_info[${index}].phone`}
                          error={errors.agent_info?.[index]?.phone}
                          value={values.agent_info[index].phone}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Phone"}
                        />
                      </div>
                      <div className="w-full mb-3">
                        <Input
                          label={"Website"}
                          name={`agent_info[${index}].url`}
                          error={errors.agent_info?.[index]?.url}
                          value={values.agent_info[index].url}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Website"}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-end w-full">
                    <button
                      type="button"
                      onClick={() => {
                        push({
                          address: "",
                          email: "",
                          name: "",
                          phone: "",
                          position: "",
                          url: "",
                        });
                      }}
                      className="flex w-[150px] rounded-xl h-[42px] pb-10 justify-center bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
                    >
                      Add+
                    </button>
                  </div>
                </>
              )}
            </FieldArray>
          </div>

          <div className="mt-6 flex space-x-2">
            <button
              type="button"
              onClick={() => handleClose()}
              className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
            >
              Close
            </button>
            <button
              type="submit"
              className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
            >
              Save
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddAgentForm;
