import * as Yup from "yup";

export const directoryValidationSchema = Yup.object({
  title: Yup.string(),
  details: Yup.object({
    address: Yup.string().required("Company Address is required"),
    area: Yup.string(),
    password: Yup.string().required("Company Password is required"),
    name: Yup.string().required("Company Name is required"),
    phone: Yup.string().required("Company Phone is required"),
    notes: Yup.string(),
    url: Yup.string().required("Company URL is required"),
  }),
});
