import { useRef } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import UserForm from "../../components/users/UserForm";
import { useToast } from "../../components/Toast/toastHook";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { GET_USER_BY_ID, UPDATE_USER } from "../../graphql/userQueries";
import {
	GET_COMPANY_OFFICES,
	GET_COMPANY_USERS,
} from "../../graphql/companyQueries";
import { useParams } from "react-router-dom";

const UpdateUser = () => {
	const { companyId, userId } = useParams();
	const [offices, setOffices] = useState([]);
	const [employee, setEmployee] = useState({});
	const [initialValues, setInitialValues] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		licenseNo: "",
		defaultOffice: "",
		role: "",
	});
	const roles = [
		{ _id: "649b89b497d37a86f3b3b4fd", name: "Admin" },
		{ _id: "649b89b497d37a86f3b3b4fe", name: "Agent" },
		{ _id:"6735c677b800511265dd77c8", name: "Buyer Agent"}
	];
	const {
		data: userData,
		loading: userLoading,
		error: userError,
	} = useQuery(GET_USER_BY_ID, {
		variables: { userId },
	});
	const {
		data: officeData,
		loading: officLoading,
		error: officeError,
	} = useQuery(GET_COMPANY_OFFICES, {
		variables: { companyId },
	});

	const formikRef = useRef();
	const toast = useToast(4000);

	const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER, {
		refetchQueries: [
			{
				query: GET_COMPANY_USERS,
				variables: {
					companyId,
				},
			},
		],
		onCompleted(result) {
			toast(
				"success",
				`Successfully saved ${result.updateUser?.firstName} ${result.updateUser?.lastName}!`
			);
		},
		onError(e) {
			console.error(e);
			toast("error", `There was an issue saving the data!`);
		},
	});

	const handleSave = ({
		firstName,
		lastName,
		email,
		phone,
		defaultOffice,
		licenseNo,
		role,
	}) => {
		updateUser({
			variables: {
				userInput: {
					firstName,
					lastName,
					email,
					phone: parseFloat(phone),
					defaultOffice,
					licenseNo,
					role,
					_id: userId,
					company: companyId,
				},
			},
		});
	};

	useEffect(() => {
		if (officeData) setOffices(officeData.getCompanyOffice);
	}, [officeData]);

	useEffect(() => {
		if (userData) {
			setInitialValues({
				...userData.getUserById,
				role: userData.getUserById.role?._id,
				defaultOffice: userData.getUserById.defaultOffice?._id,
			});
			setEmployee(userData.getUserById);
		}
	}, [userData]);

	if (userLoading || officLoading) return <Spinner />;
	return (
		<div id="AddUser">
			<Breadcrumb
				pageName={`Update: ${employee.firstName} ${employee.lastName}`}
			/>
			<UserForm
				initialValues={initialValues}
				formikRef={formikRef}
				handleSave={handleSave}
				type="edit"
				roles={roles}
				offices={offices}
			/>
		</div>
	);
};

export default UpdateUser;
