// const AdminAutocompleteCheckbox = ({
//   options,
//   rootKey,
//   formik,
//   dropdownRef,
// }) => {
//   const toggleOpen = () => {
//     formik.setFieldValue(`isOpen.${rootKey}`, !formik.values.isOpen[rootKey]);
//     formik.setFieldValue(
//       `isOpen.${rootKey === "office" ? "agent" : "office"}`,
//       false
//     );
//   };

//   const handleCheckboxChange = (value) => {
//     if (formik.values.selectedItems[rootKey]?.includes(value)) {
//       formik.setFieldValue(
//         `selectedItems.${rootKey}`,
//         formik.values.selectedItems[rootKey]?.filter((item) => item !== value)
//       );
//     } else {
//       formik.setFieldValue(`selectedItems.${rootKey}`, [
//         ...formik.values.selectedItems[rootKey],
//         value,
//       ]);
//     }
//   };

//   const filteredOptions = options?.filter((option) =>
//     (option.name || option.fullName)
//       ?.toLowerCase()
//       .includes(formik.values.search[rootKey]?.toLowerCase())
//   );

//   const onSearchChange = (e) => {
//     if (!!e.target.value) {
//       formik.setFieldValue(`isOpen.${rootKey}`, ""); // Fix here
//       formik.setFieldValue(`search.${rootKey}`, e.target.value);
//     } else {
//       formik.setFieldValue(`search.${rootKey}`, e.target.value);
//     }
//   };

//   const getSelectedItemsDisplay = () => {
//     const selectedItems = formik.values.selectedItems[rootKey] || [];
//     if (selectedItems.length > 0) {
//       return selectedItems
//         .map((itemId) => {
//           const item = options.find(
//             (option) => option._id === itemId || option.id === itemId
//           );
//           return item ? item.name || item.fullName : "";
//         })
//         .filter(Boolean)
//         .join(", "); // comma-separated selected items
//     }
//     return formik.values.search[rootKey] || ""; // search term if nothing selected
//   };

//   return (
//     <div className="relative">
//       <input
//         type="text"
//         value={formik.values.search[rootKey]}
//         onChange={onSearchChange}
//         onClick={toggleOpen}
//         placeholder={`Search ${rootKey}...`}
//         className="w-[350px] rounded-md border-[1px] border-[#d9d9d9] bg-transparent py-3 px-3 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter placeholder:text-sm "
//       />
//       {formik.values.isOpen[rootKey] && (
//         <div
//           ref={dropdownRef}
//           className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg overflow-auto max-h-96"
//         >
//           <ul className="py-1">
//             {filteredOptions?.map((item) => (
//               <li
//                 key={item._id || item.id}
//                 className="px-4 py-2 flex items-center justify-between hover:bg-gray-100"
//               >
//                 <label className="flex items-start">
//                   <input
//                     type="checkbox"
//                     className="form-checkbox h-5 w-5 text-blue-500"
//                     value={item._id || item.id}
//                     checked={formik.values.selectedItems[rootKey]?.includes(
//                       item._id || item.id
//                     )}
//                     onChange={() => handleCheckboxChange(item._id || item.id)}
//                   />
//                   <span className="ml-2 text-sm">
//                     {item.name || item.fullName}
//                   </span>
//                 </label>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AdminAutocompleteCheckbox;

import React, { useEffect } from "react";

const AdminAutocompleteCheckbox = ({
  options,
  rootKey,
  formik,
  dropdownRef,
  dependentKey, // "defaultOffice" in this case
  dependencyValue, // List of selected office IDs
}) => {
  const toggleOpen = () => {
    formik.setFieldValue(`isOpen.${rootKey}`, !formik.values.isOpen[rootKey]);
  };

  const handleCheckboxChange = (value) => {
    const selectedItems = formik.values.selectedItems[rootKey] || [];
    if (selectedItems.includes(value)) {
      formik.setFieldValue(
        `selectedItems.${rootKey}`,
        selectedItems.filter((item) => item !== value)
      );
    } else {
      formik.setFieldValue(`selectedItems.${rootKey}`, [
        ...selectedItems,
        value,
      ]);
    }
  };

  // Filter options based on search and selected offices (dependencyValue)
  const filteredOptions = options?.filter((option) => {
    const matchesSearch = (option.name || option.fullName)
      ?.toLowerCase()
      .includes(formik.values.search[rootKey]?.toLowerCase());

    // Check if dependencyValue is empty or contains selected office IDs
    if (
      dependentKey &&
      Array.isArray(dependencyValue) &&
      dependencyValue.length > 0
    ) {
      return (
        matchesSearch && dependencyValue.includes(option[dependentKey]?._id)
      );
    }

    // If no offices are selected, show all options that match the search
    return matchesSearch;
  });

  const onSearchChange = (e) => {
    const value = e.target.value;
    formik.setFieldValue(`search.${rootKey}`, value);
    if (!!value) {
      formik.setFieldValue(`isOpen.${rootKey}`, true); // Keep dropdown open during search
    } else {
      formik.setFieldValue(`isOpen.${rootKey}`, false);
    }
  };

  // Helper to get display text for selected items
  const getSelectedItemsDisplay = () => {
    const selectedItems = formik.values.selectedItems[rootKey] || [];
    if (selectedItems.length > 0) {
      return selectedItems
        .map((itemId) => {
          const item = options.find(
            (option) => option._id === itemId || option.id === itemId
          );
          return item ? item.name || item.fullName : "";
        })
        .filter(Boolean)
        .join(", "); // comma-separated selected items
    }
    return formik.values.search[rootKey] || ""; // search term if nothing selected
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={getSelectedItemsDisplay()} // Show selected items or search term
        onChange={onSearchChange}
        onClick={toggleOpen}
        placeholder={`Search ${rootKey}...`}
        className="w-[350px] rounded-md border-[1px] border-[#d9d9d9] bg-transparent py-3 px-3 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter placeholder:text-sm "
      />
      {formik.values.isOpen[rootKey] && (
        <div
          ref={dropdownRef}
          className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg overflow-auto max-h-96"
        >
          <ul className="py-1">
            {filteredOptions?.map((item) => (
              <li
                key={item._id || item.id}
                className="px-4 py-2 flex items-center justify-between hover:bg-gray-100"
              >
                <label className="flex items-start">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-500"
                    value={item._id || item.id}
                    checked={formik.values.selectedItems[rootKey]?.includes(
                      item._id || item.id
                    )}
                    onChange={() => handleCheckboxChange(item._id || item.id)}
                  />
                  <span className="ml-2 text-sm">
                    {item.name || item.fullName}
                  </span>
                </label>
              </li>
            ))}
            {filteredOptions.length === 0 && (
              <li className="px-4 py-2 text-gray-500">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AdminAutocompleteCheckbox;
