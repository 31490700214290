import { useRef } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import UserForm from "../../components/users/UserForm";
import { useToast } from "../../components/Toast/toastHook";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { ADD_USER } from "../../graphql/userQueries";
import {
  GET_COMPANY_OFFICES,
  GET_COMPANY_USERS,
} from "../../graphql/companyQueries";
import { useParams } from "react-router-dom";

const AddUser = () => {
  const { companyId } = useParams();
  const [offices, setOffices] = useState([]);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    licenseNo: "",
    defaultOffice: "",
    role: "",
  });
  const roles = [
    { _id: "649b89b497d37a86f3b3b4fd", name: "Admin" },
    { _id: "649b89b497d37a86f3b3b4fe", name: "Agent" },
    { _id:"6735c677b800511265dd77c8", name: "Buyer Agent"}
  ];
  const {
    data: officeData,
    loading: officLoading,
    error: officeError,
  } = useQuery(GET_COMPANY_OFFICES, {
    variables: { companyId },
  });

  const formikRef = useRef();
  const toast = useToast(4000);

  const [addUser, { data, loading, error }] = useMutation(ADD_USER, {
    refetchQueries: [
      {
        query: GET_COMPANY_USERS,
        variables: {
          companyId,
        },
      },
    ],
    onCompleted(result) {
      toast(
        "success",
        `Successfully saved ${result.addUser?.firstName} ${result.addUser?.lastName}!`
      );
      formikRef.current?.resetForm();
    },
    onError(e) {
      console.error(e);
      toast("error", e?.message ?? "Something went wrong!");
    },
  });
  const handleSave = (values) => {
    addUser({
      variables: {
        userInput: { ...values, company: companyId, phone: +values.phone },
      },
    });
  };

  useEffect(() => {
    if (officeData) setOffices(officeData.getCompanyOffice);
  }, [officeData]);
  return (
    <div id="AddUser">
      <Breadcrumb pageName="Add a User" />
      <UserForm
        initialValues={initialValues}
        formikRef={formikRef}
        handleSave={handleSave}
        type="add"
        roles={roles}
        offices={offices}
      />
    </div>
  );
};

export default AddUser;
