import React, { useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import Modal from "../../components/modal/Modal";
import AddAgentForm from "../../components/directory/addAgentForm";

const CompanyView = ({ companyId, handleBackClick, companyName, details }) => {
  const [showModal, setShowModal] = useState(false);
  const [formType, setFormType] = useState("add");

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="w-full pt-10 flex flex-col ">
        <div
          onClick={handleBackClick}
          className="w-full cursor-pointer flex items-center flex-row gap-4"
        >
          <IoIosArrowRoundBack color="#212636" size={30} />
          <p className="font-normal text-[20px] text-[#212636]">Back</p>
        </div>
        <h1 className="my-6 text-[30px] font-medium">{companyName}</h1>
        <div className="flex flex-row flex-wrap gap-x-10 border border-gray-200 p-4 rounded-2xl shadow-sm">
          <div className="h-20 flex items-center justify-end w-full px-10">
            <button
              onClick={() => setShowModal(true)}
              className="bg-[#B4C540] h-[36px] px-5 text-center rounded-xl text-[#FFFFFF]"
            >
              + Add Agent
            </button>
          </div>
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-4 px-4 font-medium text-black xl:pl-11">
                  Name
                </th>
                <th className="py-4 px-4 font-medium text-black">Email</th>
                <th className="py-4 px-4 font-medium text-black">
                  Phone Number
                </th>
                <th className="py-4 px-4 font-medium text-black">Address</th>
                <th className="py-4 px-4 font-medium text-black">Position</th>
              </tr>
            </thead>
            <tbody>
              {details?.length > 0 ? (
                details?.map((user, index) => (
                  <tr key={index} className="border-b border-[#eee]">
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.name}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.email}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.phone}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.address}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.position}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="py-4 px-4 text-center">
                    No agents added yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Modal
          title={"Add Agent"}
          showModal={showModal}
          setShowModal={(value) => {
            setShowModal(value);
          }}
        >
          <AddAgentForm
            companyId={companyId}
            handleClose={handleClose}
            onDirectoryListAdded={() => {}}
            formType={formType}
          />
        </Modal>
      </div>
    </>
  );
};
export default CompanyView;
